<template>
    <c-grid class="widths-100-all spacing-lg-all" v-if="order.data">
        <c-grid-item>
            <c-grid class="widths-100-all spacing-sm-all">
                <c-grid-item class="flex">
                    <span>Subtotal:</span>
                    <span class="ml-auto">${{ order.data.invoice.subtotal }}</span>
                </c-grid-item>
                <c-grid-item class="flex" v-if="order.data.invoice.subtotal != undefined">
                    <span class="mr-auto">Shipping:</span>
                    <span v-if="order.data.invoice.shipping == 0">Free</span>
                    <span v-else>${{ order.data.invoice.shipping }}</span>
                </c-grid-item>
                <c-grid-item class="flex" v-if="order.data.coupon">
                    <div class="flex f-align-center">
                        <span>Coupon:</span>
                        <span
                            class="ml-1 text-sm text-primary"
                            v-if="order.data.status == 'Pending'"
                            @click="removeCoupon()"
                            style="cursor: pointer"
                        >
                            {{ order.data.coupon.code }}
                            <i class="fas fa-times"></i>
                        </span>
                    </div>
                    <span class="ml-auto">-${{ order.data.invoice.coupon }}</span>
                </c-grid-item>
                <c-grid-item class="flex">
                    <span>Tax:</span>
                    <span class="ml-auto">${{ order.data.invoice.tax }}</span>
                </c-grid-item>
                <c-grid-item class="flex text-bold text-lg">
                    <span>Total:</span>
                    <span class="ml-auto">${{ order.data.invoice.total }}</span>
                </c-grid-item>
                <c-grid-item class="flex text-green text-bold" v-if="order.data.invoice.refund">
                    <span>Refunded:</span>
                    <span class="ml-auto">${{ order.data.invoice.refund }}</span>
                </c-grid-item>
                <c-grid-item>
                    <p class="mt-3 text-sm text-light" v-if="order.data.datePlaced?.toDate">
                        Order placed on {{ order.data.datePlaced?.toDate().toLocaleDateString() }}
                    </p>
                    <p class="mt-1 text-sm text-light" v-if="order.data.payment.last4">
                        {{ order.data.status == "Pending" ? "Paying" : "Paid" }} with card ending in
                        {{ order.data.payment.last4 }}
                    </p>
                    <p class="mt-1 text-md text-primary text-bold" v-if="order.data.status != 'Pending'">
                        Order Status: {{ order.data.status }}
                    </p>
                </c-grid-item>
            </c-grid>
        </c-grid-item>
        <c-grid-item
            v-if="!order.data.coupon && coupon && order.data.status != 'Completed' && order.data.status != 'Cancelled'"
        >
            <c-grid>
                <c-grid-item class="width-grow-all">
                    <c-textbox type="uppercase" :placeholder="'Coupon Code'" v-model="couponCode" />
                    <p class="flex mt-1 text-red text-sm" v-if="couponError">
                        {{ couponError }}
                    </p>
                </c-grid-item>
                <c-grid-item class="width-shrink-all">
                    <c-button @click="applyCoupon()">Apply</c-button>
                </c-grid-item>
            </c-grid>
        </c-grid-item>
    </c-grid>
</template>

<script lang="ts">
import OrderController from "@/controllers/order-controller";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "c-order-invoice",
    props: {
        orderId: {
            type: String,
            required: true,
        },
        coupon: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const orderController = new OrderController(props.orderId);
        const couponCode = ref("");
        const couponError = ref<string>();

        async function applyCoupon(): Promise<void> {
            await orderController
                .applyCoupon(couponCode.value)
                .then(() => {
                    couponError.value = undefined;
                })
                .catch((error) => {
                    couponError.value = error;
                });
        }

        function removeCoupon(): void {
            couponCode.value = "";
            orderController.removeCoupon();
        }

        return { order: orderController.order, couponCode, couponError, applyCoupon, removeCoupon };
    },
});
</script>
