
import { inject, ref } from "vue";
import { Database, Auth } from "vuebase";
import { Product } from "./main";
import pluralize from "pluralize";
import OrderController from "./controllers/order-controller";
import { useRouter } from "vue-router";

export default {
    name: "CohoPrints",
    setup() {
        const database = inject(Database.InjectionKey);
        const auth = Auth.auth();
        const products = database?.collection<Product>("products").documents();
        const order = ref(new OrderController().order);
        const router = useRouter();

        router.afterEach(() => {
            order.value = new OrderController().order;
        });

        return {
            auth,
            order,
            products,
            pluralize,
        };
    },
};
