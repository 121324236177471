
import { FeedItem } from "@/main";
import { defineComponent, inject, onMounted, ref } from "vue";
import { Database } from "vuebase";

export default defineComponent({
    name: "c-feed",
    props: {
        category: {
            type: String,
            required: false,
        },
        limit: {
            type: Number,
            required: false,
        },
    },
    setup() {
        const feedElement = ref();
        const size = ref("20");
        const database = inject(Database.InjectionKey);
        const feed = database?.collection<FeedItem>("feed").documents();
        window.addEventListener("resize", resize);

        onMounted(() => resize());

        function resize(): void {
            const width = feedElement.value?.$el.clientWidth;

            if (width >= 800) {
                size.value = "20";
            } else if (width < 800 && width >= 600) {
                size.value = "25";
            } else if (width < 600 && width >= 400) {
                size.value = "33";
            } else {
                size.value = "50";
            }
        }

        return { feedElement, feed, size };
    },
});
