import { watch } from "vue";
import deepEqual from "deep-equal";
import {
    Card,
    CartItem,
    Coupon,
    DiscountType,
    Order,
    OrderStatus,
    Parcel,
    Pricing,
    PricingModel,
    Product,
    ShippingLabel,
    ShippingMethod,
} from "@/main";
import { Database, Functions, Storage } from "vuebase";

const blankOrder: Order = {
    cart: [],
    contact: {},
    shipping: {
        address: {},
        method: {},
        dropShipping: false,
    },
    payment: {},
    invoice: {},
    status: "Pending" as OrderStatus,
};

export default class OrderController {
    _database = Database.database;
    _functions = Functions.functions;
    _storage = Storage.storage;
    _products: Database.Document<Product>[];
    order: Database.Document<Order>;
    testMode = process.env.NODE_ENV == "development" ? true : false;

    constructor(orderId?: string) {
        this._products = this._database.collection<Product>("products").documents();

        orderId = orderId ?? localStorage.getItem("orderId") ?? undefined;
        this.order = this._database.collection<Order>("orders").document(orderId);
        !orderId && localStorage.setItem("orderId", this.order.id);

        // React to any changes which could affect the price
        // Note: Including any user-input fields (textboxes) could cause excessive saves.
        watch(
            () => [
                this.order.data?.cart,
                this.order.data?.coupon,
                this.order.data?.shipping.method,
                this.order.data?.invoice,
                this.order.data?.status,
            ],
            () => {
                this.updateInvoice();
                this.order.save();
            },
            { deep: true },
        );
    }

    clearOrder(): void {
        localStorage.removeItem("orderId");
        this.order = this._database.collection<Order>("orders").document();
        localStorage.setItem("orderId", this.order.id);
    }

    addItem(item: CartItem): void {
        !this.order.data && (this.order.data = blankOrder);
        this.order.data.cart.push(item);
    }

    removeItem(item: CartItem): void {
        !this.order.data && (this.order.data = blankOrder);
        const index = this.order.data.cart.findIndex((i) => deepEqual(item, i));
        index != -1 && this.order.data.cart.splice(index, 1);
    }

    cancel(): Promise<void> {
        if (this.order.data) {
            this.order.data.status = OrderStatus.Cancelled;
            return this.order.save().then(() => {
                return this._functions
                    .call("sendCancellation", { orderId: this.order.id, order: this.order.data })
                    .then();
            });
        }

        return Promise.reject();
    }

    delete(): Promise<void> {
        return this.order.delete().then(() => {
            return this._storage.delete("orders/" + this.order.id + "/");
        });
    }

    async applyCoupon(couponCode: string): Promise<void> {
        !this.order.data && (this.order.data = blankOrder);
        const coupon = this._database.collection<Coupon>("coupons").document(couponCode.toUpperCase());

        await coupon?.wait().catch(() => {
            throw "Invalid coupon";
        });

        if (!coupon.data || (coupon.data.starts && coupon.data.starts.seconds >= Date.now() / 1000)) {
            throw "Invalid coupon";
        }

        if (coupon.data.expires && coupon.data.expires.seconds <= Date.now() / 1000) {
            throw "Coupon has expired";
        }

        this.order.data.coupon = { code: coupon.id, ...coupon.data };
        this.order.data.invoice.coupon = this.calculateCouponDiscount();
    }

    removeCoupon(): void {
        delete this.order.data?.coupon;
    }

    getPrice(pricings: Pricing[], item: Partial<CartItem>): number {
        let price = 0;

        for (const pricing of pricings) {
            switch (pricing.model) {
                case PricingModel.FlatFee:
                    price += pricing.price;
                    break;
                case PricingModel.PerHour:
                    price += pricing.price * (item.hours ?? 0);
                    break;
                case PricingModel.PerInch:
                    price += pricing.price * ((item.size?.height ?? 0) * 2 + (item.size?.width ?? 0) * 2);
                    break;
                case PricingModel.PerSquareInch:
                    price += pricing.price * (item.size?.height ?? 0) * (item.size?.width ?? 0);
                    break;
            }
        }

        return price;
    }

    calculateItemPrice(item: Partial<CartItem>): number {
        const product = this._products.find((product) => product.id == item.productId)?.data;
        let price = 0;

        if (product) {
            price += this.getPrice(product.pricing, item);

            for (const selectionName in item.selections) {
                const selection = product.selections.find((s) => s.name == selectionName);
                const option = selection?.options.find((o) => o.name == item.selections?.[selectionName]);
                if (option) {
                    price += this.getPrice(option.pricing, item);
                }
            }
        }

        return this.roundPrice(price * (item.quantity ?? 1));
    }

    calculateCouponDiscount(): number {
        const coupon = this.order.data?.coupon;
        let discount = 0;

        switch (coupon?.type) {
            case DiscountType.Percentage:
                discount = this.calculateSubtotal() * coupon.discount;
                break;
            case DiscountType.Fixed:
                discount = Math.min(coupon.discount, this.calculateSubtotal());
                break;
        }

        if (coupon?.freeShipping) {
            discount += this.calculateShippingCosts();
        }

        return this.roundPrice(discount);
    }

    calculateSubtotal(): number {
        let subtotal = 0;

        for (const item of this.order.data?.cart ?? []) {
            subtotal += this.calculateItemPrice(item);
        }

        return this.roundPrice(subtotal);
    }

    calculateShippingCosts(): number {
        return this.roundPrice(this.order.data?.shipping.method.price ?? 0);
    }

    calculateTax(): number {
        const preTaxTotal = this.calculatePreTaxTotal();
        const taxRate = 0.101; // 10.1% Tax as of 09-01-2021
        return this.roundPrice(preTaxTotal * taxRate);
    }

    calculatePreTaxTotal(): number {
        let preTaxTotal = 0;

        preTaxTotal += this.calculateSubtotal();
        preTaxTotal += this.calculateShippingCosts();
        preTaxTotal -= this.calculateCouponDiscount();

        return this.roundPrice(preTaxTotal);
    }

    calculateTotal(): number {
        let total = 0;

        total += this.calculatePreTaxTotal();
        total += this.calculateTax();

        return this.roundPrice(total);
    }

    updateInvoice(): void {
        if (!this.order.data) return;

        // First we want to ensure all the item prices are set properly
        for (const item of this.order.data.cart ?? []) {
            item.price = this.calculateItemPrice(item);
        }

        this.order.data.invoice.subtotal = this.calculateSubtotal();
        this.order.data.invoice.shipping = this.calculateShippingCosts();
        this.order.data.invoice.coupon = this.calculateCouponDiscount();
        this.order.data.invoice.tax = this.calculateTax();
        this.order.data.invoice.total = this.calculateTotal();
    }

    validatePrice(): boolean {
        let priceInvalid = false;

        if (!this.order.data) return false;

        priceInvalid = priceInvalid || this.order.data.invoice.subtotal != this.calculateSubtotal();
        priceInvalid = priceInvalid || this.order.data.invoice.shipping != this.calculateShippingCosts();
        priceInvalid = priceInvalid || this.order.data.invoice.coupon != this.calculateCouponDiscount();
        priceInvalid = priceInvalid || this.order.data.invoice.tax != this.calculateTax();
        priceInvalid = priceInvalid || this.order.data.invoice.total != this.calculateTotal();

        return !priceInvalid;
    }

    roundPrice(price: number): number {
        return Math.round(price);
        // The line below rounds to the nearest two decimal points.
        // For now, opting to show all prices as dollars-only for simplicity
        // return Math.round(price * 100) / 100.0;
    }

    async getShippingMethods(parcel?: Parcel): Promise<ShippingMethod[]> {
        if (this.order.data) {
            if (!parcel) {
                parcel = this.estimateParcel();
            }

            return this._functions
                ?.call("getShippingMethods", {
                    address: this.order.data.shipping.address,
                    parcel,
                    email: this.order.data.contact.email,
                    testMode: this.testMode,
                })
                .then((response) => {
                    console.log(response);
                    return response.data as ShippingMethod[];
                });
        }

        return Promise.reject();
    }

    estimateParcel(): Parcel {
        const parcel: Parcel = {
            width: 0,
            height: 0,
            length: 0,
            distance_unit: "in",
            weight: 0,
            mass_unit: "oz",
        };
        let rigid = false;

        if (!this.order.data) return parcel;

        // Calculate the size and weight of each item in the cart
        for (const item of this.order.data.cart) {
            const product = this._products.find((product) => product.id == item.productId);
            if (!product?.data) continue;

            let itemWidth = product.data.packaging.size.width;
            let itemHeight = product.data.packaging.size.height;
            let itemLength = product.data.packaging.size.length;
            rigid = rigid || product.data.packaging.rigid;

            if (item.size) {
                itemWidth += Math.max(item.size.width, item.size.height);
                itemHeight += Math.min(item.size.width, item.size.height);
            }

            // Calculate the size of the selected options
            for (const selectionName in item.selections) {
                const optionName = item.selections[selectionName];
                const selection = product.data.selections.find((s) => s.name == selectionName);
                const option = selection?.options.find((o) => o.name == optionName);

                itemWidth += option?.packaging.size.width ?? 0;
                itemHeight += option?.packaging.size.height ?? 0;
                itemLength += option?.packaging.size.length ?? 0;
                rigid = rigid || (option?.packaging.rigid ?? false);
            }

            // Calculate the weight of the product
            let itemWeight = 0;
            switch (product.data.packaging.mass.measurement) {
                case "Flat":
                    itemWeight += product.data.packaging.mass.weight;
                    break;
                case "Area":
                    itemWeight += itemWidth * itemHeight * product.data.packaging.mass.weight;
                    break;
                case "Perimeter":
                    itemWeight += (itemWidth * 2 + itemHeight * 2) * product.data.packaging.mass.weight;
                    break;
            }

            // Calculate the weight of the selected options
            for (const selectionName in item.selections) {
                const optionName = item.selections[selectionName];
                const selection = product.data.selections.find((s) => s.name == selectionName);
                const option = selection?.options.find((o) => o.name == optionName);

                switch (option?.packaging.mass.measurement) {
                    case "Flat":
                        itemWeight += option.packaging.mass.weight;
                        break;
                    case "Area":
                        itemWeight += itemWidth * itemHeight * option.packaging.mass.weight;
                        break;
                    case "Perimeter":
                        itemWeight += (itemWidth * 2 + itemHeight * 2) * option.packaging.mass.weight;
                        break;
                }
            }

            // Increase the parcel size to fit this cart item
            if (parcel.width < itemWidth) parcel.width = itemWidth;
            if (parcel.height < itemHeight) parcel.height = itemHeight;
            parcel.length += itemLength * (item.quantity ?? 1);
            parcel.weight += itemWeight * (item.quantity ?? 1);
        }

        // If the print is large and flexible, ship it in a 3" tube.
        if (!rigid && (parcel.width > 24 || parcel.height > 18)) {
            parcel.width = 3;
            parcel.length = 3;
        }

        // Account for the weight of the cardboard packaging
        const packageSurfaceArea =
            2 * (parcel.width * parcel.height + parcel.width * parcel.length + parcel.height * parcel.length);
        parcel.weight += packageSurfaceArea * 0.014;

        // Round everything to 3 decimal places, for shipping APIs
        parcel.width = parseFloat(parcel.width.toFixed(3));
        parcel.height = parseFloat(parcel.height.toFixed(3));
        parcel.length = parseFloat(parcel.length.toFixed(3));
        parcel.weight = parseFloat(parcel.weight.toFixed(3));

        // Order the fields according to size.
        const dimensionArray = [parcel.width, parcel.height, parcel.length].sort();
        parcel.width = dimensionArray[2];
        parcel.height = dimensionArray[1];
        parcel.length = dimensionArray[0];

        return parcel;
    }

    buyShippingLabel(shippingMethod: ShippingMethod): Promise<void> {
        return this._functions
            ?.call("buyShippingLabel", { rate: shippingMethod.id, testMode: this.testMode })
            .then((response) => {
                if (this.order.data) {
                    this.order.data.shipping.label = response.data as ShippingLabel;
                    return this.order.save();
                }
            });

        return Promise.reject();
    }

    placeOrder(): Promise<void> {
        if (this.order.data) {
            this.order.data.datePlaced =
                Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp;
            this.order.data.status = OrderStatus.Processing;

            return this.order
                .save()
                .then(() => {
                    return this.sendInvoice();
                })
                .then(() => {
                    return this.clearOrder();
                });
        }
        return Promise.reject();
    }

    async setupCard(paymentMethod: Card): Promise<void> {
        return this._functions?.call("setupCard", { card: paymentMethod, testMode: this.testMode }).then((response) => {
            if (this.order.data) {
                this.order.data.payment = { card: response.data.id, last4: response.data.card.last4 };
                return this.order.save();
            }

            return Promise.reject();
        });
    }

    processPayment(): Promise<void> {
        return this._functions
            .call("processPayment", {
                card: this.order.data?.payment.card,
                amount: this.order.data?.invoice.total,
                testMode: this.testMode,
            })
            .then((result) => {
                if (this.order.data && result.data.status == "succeeded") {
                    this.order.data.payment.charge = result.data.id;
                    this.order.data.dateCompleted =
                        Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp;
                    this.order.data.status = OrderStatus.Completed;
                }

                return Promise.reject();
            });
    }

    issueRefund(refund: number): Promise<void> {
        return this._functions
            .call("issueRefund", {
                charge: this.order.data?.payment.charge,
                amount: refund,
                testMode: this.testMode,
            })
            .then((result) => {
                if (this.order.data && result.data.status == "succeeded") {
                    (this.order.data.payment.refunds = this.order.data.payment.refunds ?? []).push(result.data.id);
                    this.order.data.invoice.refund = (this.order.data.invoice.refund ?? 0) + result.data.amount / 100;
                    return Promise.resolve();
                }

                return Promise.reject();
            });
    }

    sendInvoice(): Promise<void> {
        const order = JSON.parse(JSON.stringify(this.order.data)) as unknown as Order;

        // Convert to user-friendly product names.
        for (const item of order.cart) {
            const productName = this._products.find((p) => p.id == item.productId)?.data?.name ?? item.productId;
            item.productId = productName;
        }

        return this._functions.call("sendInvoice", { orderId: this.order.id, order: order }).then();
    }
}
