<template>
    <c-select
        :label="label ?? 'Size'"
        :options="sizes"
        :display="(size) => size?.width + ' &times; ' + size?.height"
        v-model="size"
        @close="error = undefined"
    >
        <template #dropdown="slotProps">
            <c-grid class="widths-33-all spacing-none-all">
                <c-grid-item v-for="category in categories" :key="category">
                    <h3 class="mt-2 flex f-justify-center">{{ category }}</h3>
                    <div
                        class="option f-justify-center"
                        :class="{ selected: slotProps.isOptionSelected(option) }"
                        v-for="option in slotProps.options.filter((option) => option.category == category)"
                        :key="option"
                        @click="slotProps.select(option)"
                    >
                        {{ slotProps.display(option) }}
                    </div>
                </c-grid-item>
            </c-grid>
            <div class="custom-size" @click.stop @keydown.enter.stop="setCustomSize(slotProps.select)">
                <c-grid class="f-align-center f-justify-center spacing-xs-all">
                    <c-grid-item class="width-grow-all">
                        <c-textbox type="float" v-model="customSize.width" suffix="in" />
                    </c-grid-item>
                    <c-grid-item class="width-shrink-all">&times;</c-grid-item>
                    <c-grid-item class="width-grow-all">
                        <c-textbox type="float" v-model="customSize.height" suffix="in" />
                    </c-grid-item>
                    <c-grid-item class="width-shrink-all">
                        <c-button @click="setCustomSize(slotProps.select)">Ok</c-button>
                    </c-grid-item>
                    <c-grid-item class="width-100-all error text-red text-center" v-if="error">
                        <span>{{ error }}</span>
                    </c-grid-item>
                </c-grid>
            </div>
        </template>
    </c-select>
</template>

<script lang="ts">
import { ref, watch, defineComponent } from "vue";

export default defineComponent({
    name: "c-print-size-picker",
    props: {
        label: {
            type: String,
        },
        minLongEdge: {
            type: Number,
            default: 7,
        },
        minShortEdge: {
            type: Number,
            default: 5,
        },
        maxShortEdge: {
            type: Number,
            default: 40,
        },
        maxLongEdge: {
            type: Number,
            default: 72,
        },
    },
    setup(props) {
        const sizes: PrintSize[] = [
            { category: "Standard", width: 8, height: 10 },
            { category: "Standard", width: 11, height: 14 },
            { category: "Standard", width: 16, height: 20 },
            { category: "Standard", width: 16, height: 24 },
            { category: "Standard", width: 20, height: 24 },
            { category: "Standard", width: 24, height: 36 },
            { category: "Standard", width: 36, height: 48 },
            { category: "Standard", width: 40, height: 60 },
            { category: "Pano", width: 8, height: 14 },
            { category: "Pano", width: 8, height: 16 },
            { category: "Pano", width: 12, height: 24 },
            { category: "Pano", width: 12, height: 36 },
            { category: "Pano", width: 16, height: 36 },
            { category: "Pano", width: 16, height: 48 },
            { category: "Pano", width: 24, height: 48 },
            { category: "Pano", width: 24, height: 72 },
            { category: "Pano", width: 36, height: 72 },
            { category: "Square", width: 8, height: 8 },
            { category: "Square", width: 12, height: 12 },
            { category: "Square", width: 16, height: 16 },
            { category: "Square", width: 20, height: 20 },
            { category: "Square", width: 24, height: 24 },
            { category: "Square", width: 30, height: 30 },
            { category: "Square", width: 36, height: 36 },
            { category: "Square", width: 40, height: 40 },
        ];

        // TODO: Filter sizes based on min and max props.

        const size = ref();
        const customSize = ref({ ...size.value });
        const error = ref<string>();

        watch(size, () => {
            customSize.value = { ...size.value, category: "Custom" };
        });

        function setCustomSize(select: (size: PrintSize) => void): void {
            const longEdge = Math.max(customSize.value.width, customSize.value.height);
            const shortEdge = Math.min(customSize.value.width, customSize.value.height);

            if (longEdge > props.maxLongEdge || shortEdge > props.maxShortEdge) {
                error.value = `The largest supported print size is ${props.maxShortEdge}x${props.maxLongEdge}`;
                return;
            }

            if (longEdge < props.minLongEdge || shortEdge < props.minShortEdge) {
                error.value = `The smallest supported print size is ${props.minShortEdge}x${props.minLongEdge}`;
                return;
            }

            error.value = undefined;
            sizes[sizes.length] = customSize.value;
            select(customSize.value);
        }

        const categories = sizes
            .map((size) => size.category)
            .filter((category, index, array) => array.indexOf(category) == index);

        return { sizes, size, customSize, categories, error, setCustomSize };
    },
});

interface PrintSize {
    category: string;
    width: number;
    height: number;
}
</script>

<style lang="scss" scoped>
.custom-size {
    background-color: #e2e2e2;
    padding: 12px;
}

.error {
    animation: slide-in 300ms ease;
}

@keyframes slide-in {
    from {
        transform: translateY(-8px);
    }
    to {
        transform: translateY(0);
    }
}
</style>
