
import { ref, watch, defineComponent } from "vue";

export default defineComponent({
    name: "c-print-size-picker",
    props: {
        label: {
            type: String,
        },
        minLongEdge: {
            type: Number,
            default: 7,
        },
        minShortEdge: {
            type: Number,
            default: 5,
        },
        maxShortEdge: {
            type: Number,
            default: 40,
        },
        maxLongEdge: {
            type: Number,
            default: 72,
        },
    },
    setup(props) {
        const sizes: PrintSize[] = [
            { category: "Standard", width: 8, height: 10 },
            { category: "Standard", width: 11, height: 14 },
            { category: "Standard", width: 16, height: 20 },
            { category: "Standard", width: 16, height: 24 },
            { category: "Standard", width: 20, height: 24 },
            { category: "Standard", width: 24, height: 36 },
            { category: "Standard", width: 36, height: 48 },
            { category: "Standard", width: 40, height: 60 },
            { category: "Pano", width: 8, height: 14 },
            { category: "Pano", width: 8, height: 16 },
            { category: "Pano", width: 12, height: 24 },
            { category: "Pano", width: 12, height: 36 },
            { category: "Pano", width: 16, height: 36 },
            { category: "Pano", width: 16, height: 48 },
            { category: "Pano", width: 24, height: 48 },
            { category: "Pano", width: 24, height: 72 },
            { category: "Pano", width: 36, height: 72 },
            { category: "Square", width: 8, height: 8 },
            { category: "Square", width: 12, height: 12 },
            { category: "Square", width: 16, height: 16 },
            { category: "Square", width: 20, height: 20 },
            { category: "Square", width: 24, height: 24 },
            { category: "Square", width: 30, height: 30 },
            { category: "Square", width: 36, height: 36 },
            { category: "Square", width: 40, height: 40 },
        ];

        // TODO: Filter sizes based on min and max props.

        const size = ref();
        const customSize = ref({ ...size.value });
        const error = ref<string>();

        watch(size, () => {
            customSize.value = { ...size.value, category: "Custom" };
        });

        function setCustomSize(select: (size: PrintSize) => void): void {
            const longEdge = Math.max(customSize.value.width, customSize.value.height);
            const shortEdge = Math.min(customSize.value.width, customSize.value.height);

            if (longEdge > props.maxLongEdge || shortEdge > props.maxShortEdge) {
                error.value = `The largest supported print size is ${props.maxShortEdge}x${props.maxLongEdge}`;
                return;
            }

            if (longEdge < props.minLongEdge || shortEdge < props.minShortEdge) {
                error.value = `The smallest supported print size is ${props.minShortEdge}x${props.minLongEdge}`;
                return;
            }

            error.value = undefined;
            sizes[sizes.length] = customSize.value;
            select(customSize.value);
        }

        const categories = sizes
            .map((size) => size.category)
            .filter((category, index, array) => array.indexOf(category) == index);

        return { sizes, size, customSize, categories, error, setCustomSize };
    },
});

interface PrintSize {
    category: string;
    width: number;
    height: number;
}
