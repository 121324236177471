<template>
    <c-grid :class="`widths-${size}-all`" ref="feedElement">
        <template
            v-for="(item, i) in feed
                .filter((item) => (category ? item.data?.categories.includes(category) : true))
                .sort((a, b) => (b.data?.dateCreated.seconds ?? 0) - (a.data?.dateCreated.seconds ?? 0))
                .slice(0, limit)"
            :key="i"
        >
            <c-grid-item>
                <c-box>
                    <c-image :zoom="true" :image="item.data.image" :arrows="true" />
                </c-box>
                <template v-if="item.data.artist">
                    <p class="mt-1 text-sm">
                        <template v-if="item.data.url">
                            <a :href="item.data.url" target="_blank">{{ item.data.artist }}</a>
                        </template>
                        <template v-else>
                            {{ item.data.artist }}
                        </template>
                    </p>
                </template>
            </c-grid-item>
        </template>
    </c-grid>
</template>

<script lang="ts">
import { FeedItem } from "@/main";
import { defineComponent, inject, onMounted, ref } from "vue";
import { Database } from "vuebase";

export default defineComponent({
    name: "c-feed",
    props: {
        category: {
            type: String,
            required: false,
        },
        limit: {
            type: Number,
            required: false,
        },
    },
    setup() {
        const feedElement = ref();
        const size = ref("20");
        const database = inject(Database.InjectionKey);
        const feed = database?.collection<FeedItem>("feed").documents();
        window.addEventListener("resize", resize);

        onMounted(() => resize());

        function resize(): void {
            const width = feedElement.value?.$el.clientWidth;

            if (width >= 800) {
                size.value = "20";
            } else if (width < 800 && width >= 600) {
                size.value = "25";
            } else if (width < 600 && width >= 400) {
                size.value = "33";
            } else {
                size.value = "50";
            }
        }

        return { feedElement, feed, size };
    },
});
</script>
