import { createApp } from "vue";
import CohoPrints from "./CohoPrints.vue";
import router from "./router";
import "./styles/theme.scss";
import "@fortawesome/fontawesome-free/js/all.js";
import CohoUI, { ImageData, ImageSize } from "coho-ui";
import Vuebase from "vuebase";
import "coho-ui/src/styles/coho-ui.scss";
import PrintSizePicker from "./components/PrintSizePicker.vue";
import OrderInvoice from "./components/OrderInvoice.vue";
import Feed from "./components/Feed.vue";

const app = createApp(CohoPrints)
    .use(CohoUI)
    .use(Vuebase, {
        firebaseConfig: {
            apiKey: "AIzaSyChTEJKCIMi5_fhwOL5XS5va75F2ZYcY5U",
            authDomain: "coho-prints.firebaseapp.com",
            databaseURL: "https://coho-prints.firebaseio.com",
            projectId: "coho-prints",
            storageBucket: "coho-prints.appspot.com",
            messagingSenderId: "736847248493",
            appId: "1:736847248493:web:e294fa4906ba1ddf3a5364",
            measurementId: "G-NXQ199CTY3",
        },
    })
    .use(router);
app.mount("#app");

app.component(PrintSizePicker.name, PrintSizePicker);
app.component(OrderInvoice.name, OrderInvoice);
app.component(Feed.name, Feed);

export interface Product {
    index?: number;
    category: ProductCategory;
    artist?: string;
    name: string;
    caption: string;
    description: string;
    images: ImageData[];
    pricing: Pricing[];
    packaging: Packaging;
    selections: {
        name: string;
        options: {
            name: string;
            description: string;
            images: unknown[];
            pricing: Pricing[];
            packaging: Packaging;
        }[];
    }[];
}

export interface Artist {
    profilePic?: ImageData;
    email: string;
    name: string;
    location: string;
    bio: string;
}

export interface Upload {
    image?: ImageData;
    email: string;
    notes: string;
    dateUploaded?: firebase.default.firestore.Timestamp;
}

export interface Order {
    cart: CartItem[];
    contact: {
        email?: string;
    };
    coupon?: { code: string } & Coupon;
    shipping: {
        address: ShippingAddress;
        method: ShippingMethod;
        label?: ShippingLabel;
        dropShipping: boolean;
        addressFrom?: ShippingAddress;
    };
    payment: {
        card?: string;
        last4?: string;
        charge?: string;
        refunds?: string[];
    };
    invoice: {
        subtotal?: number;
        shipping?: number;
        coupon?: number;
        tax?: number;
        total?: number;
        refund?: number;
    };
    status: OrderStatus;
    datePlaced?: firebase.default.firestore.Timestamp;
    dateCompleted?: firebase.default.firestore.Timestamp;
}

export interface CartItem {
    productId: string;
    selections: { [key: string]: string };
    price: number;
    quantity?: number;
    hours?: number;
    image?: ImageData;
    size?: ImageSize;
}

export interface Coupon {
    discount: number;
    expires?: firebase.default.firestore.Timestamp;
    starts?: firebase.default.firestore.Timestamp;
    freeShipping?: boolean;
    type: DiscountType;
}

export enum ProductCategory {
    Service = "Service",
    CustomPrint = "Custom Print",
    GalleryPrint = "Gallery Print",
}

export enum PricingModel {
    FlatFee = "Flat Fee",
    PerSquareInch = "Per Square Inch",
    PerInch = "Per Inch",
    PerHour = "Per Hour",
}

export enum DiscountType {
    Percentage = "Percentage",
    Fixed = "Fixed",
}

export enum OrderStatus {
    Pending = "Pending",
    Processing = "Processing",
    Completed = "Completed",
    Cancelled = "Cancelled",
}

export interface Pricing {
    price: number;
    model: PricingModel;
}

export interface Gallery {
    image: {
        full: string;
        thumbnail: string;
    };
}

export interface Packaging {
    size: {
        width: number;
        height: number;
        length: number;
    };
    mass: {
        weight: number;
        measurement: string;
    };
    rigid: boolean;
}

export interface ShippingAddress {
    name?: string;
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
}

export interface ShippingMethod {
    service?: string;
    days?: number;
    id?: string;
    price?: number;
}

export interface ShippingLabel {
    trackingNumber: string;
    trackingUrl: string;
    label: string;
}

export interface Parcel {
    length: number;
    height: number;
    width: number;
    distance_unit: string;
    weight: number;
    mass_unit: string;
}

export interface Card {
    number: string;
    expiration: string;
    cvc: string;
}

export interface FeedItem {
    image?: ImageData;
    categories: string[];
    dateCreated: firebase.default.firestore.Timestamp;
    artist?: string;
    url?: string;
}

export interface ServiceRequest {
    email: string;
    name: string;
    service: string;
    message: string;
    images: ImageData[];
    status: string;
    dateResponded?: firebase.default.firestore.Timestamp;
    dateCreated: firebase.default.firestore.Timestamp;
}
