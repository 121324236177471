<template>
    <c-root>
        <!-- <c-panel class="spacing-none">
            <div class="alertbar mt-2">
                Notice: We are temporarily closed for Spring vacation! Orders will be delayed until April 15th.
            </div>
        </c-panel> -->
        <c-navbar class="print-hide" v-if="$route.path != '/closed'">
            <template #logo>
                <c-navbar-item @click="$router.push('/')">
                    <img style="height: 40px" :src="require('@/assets/logo.svg')" />
                </c-navbar-item>
            </template>
            <template #start>
                <c-navbar-dropdown v-if="products">
                    <template #label>Products</template>
                    <template v-for="product in products" :key="product.id">
                        <c-navbar-item v-if="product?.data && product?.data.category == 'Custom Print'">
                            <router-link :to="'/product/' + product.id">
                                {{ pluralize(product.data?.name) }}
                            </router-link>
                        </c-navbar-item>
                    </template>
                </c-navbar-dropdown>
                <c-navbar-item>
                    <router-link to="/services">Services</router-link>
                </c-navbar-item>
                <c-navbar-item>
                    <router-link to="/gallery">Gallery</router-link>
                </c-navbar-item>
                <c-navbar-item>
                    <router-link to="/resources">Resources</router-link>
                </c-navbar-item>
                <c-navbar-item>
                    <router-link to="/#about">About</router-link>
                </c-navbar-item>
            </template>
            <template #end>
                <c-navbar-item v-if="auth.user">
                    <router-link to="/admin">
                        <i class="fas fa-crown"></i>
                        <span class="ml-1">Admin</span>
                    </router-link>
                </c-navbar-item>
                <c-navbar-item @click="$router.push('/cart')">
                    <router-link to="/admin">
                        <i class="fas fa-shopping-cart"></i>
                        <span class="ml-1">Cart</span>
                        <span class="ml-1" v-if="(order?.data?.cart.length ?? 0) > 0">
                            - {{ order?.data?.cart.length }}
                        </span>
                    </router-link>
                </c-navbar-item>
            </template>
            <template #mobile v-if="products">
                <template v-for="product in products" :key="product.id">
                    <c-navbar-item v-if="product?.data && product?.data.category == 'Custom Print'">
                        <router-link :to="'/product/' + product.id">
                            {{ pluralize(product.data?.name) }}
                        </router-link>
                    </c-navbar-item>
                </template>
                <c-navbar-divider></c-navbar-divider>
                <c-navbar-item>
                    <router-link to="/services">Services</router-link>
                </c-navbar-item>
                <c-navbar-item>
                    <router-link to="/gallery">Gallery</router-link>
                </c-navbar-item>
                <c-navbar-item>
                    <router-link to="/resources">Resources</router-link>
                </c-navbar-item>
                <c-navbar-item>
                    <router-link to="/#about">About</router-link>
                </c-navbar-item>
                <c-navbar-item v-if="auth.user">
                    <router-link to="/admin">
                        <i class="fas fa-crown"></i>
                        <span class="ml-1">Admin</span>
                    </router-link>
                </c-navbar-item>
                <c-navbar-item @click="$router.push('/cart')">
                    <router-link to="/admin">
                        <i class="fas fa-shopping-cart"></i>
                        <span class="ml-1">Cart</span>
                        <span class="ml-1" v-if="(order?.data?.cart.length ?? 0) > 0">
                            - {{ order?.data?.cart.length }}
                        </span>
                    </router-link>
                </c-navbar-item>
            </template>
        </c-navbar>
        <router-view :key="$route.path" />
        <c-footer class="print-hide" v-if="$route.path != '/closed'">
            <template #links>
                <ul>
                    <router-link to="/terms-and-conditions"><li>Terms &amp; Conditions</li></router-link>
                    <router-link to="/privacy-policy"><li>Privacy Policy</li></router-link>
                    <router-link to="/resources"><li>FAQs</li></router-link>
                </ul>
            </template>
            <template #brand>
                <ul class="text-sm text-light">
                    <li>
                        <img height="70" :src="require('@/assets/logo.svg')" />
                    </li>
                    <li>© {{ new Date().getFullYear() }} Coho Prints, LLC</li>
                    <li>Sammamish, WA</li>
                </ul>
            </template>
            <template #contact>
                <ul>
                    <ul class="horizontal">
                        <li>
                            <a href="https://facebook.com/cohoprints"><i class="fab fa-facebook fa-2x"></i></a>
                        </li>
                        <li>
                            <a href="https://instagram.com/cohoprints"><i class="fab fa-instagram fa-2x mx-4"></i></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/cohoprints"><i class="fab fa-twitter fa-2x"></i></a>
                        </li>
                    </ul>
                    <li><a class="small" href="mailto:daniel@cohoprints.com">daniel@cohoprints.com</a></li>
                    <li><a class="small" href="tel:+14252761482">(425) 276-1482</a></li>
                </ul>
            </template>
        </c-footer>
    </c-root>
</template>

//
<script lang="ts">
import { inject, ref } from "vue";
import { Database, Auth } from "vuebase";
import { Product } from "./main";
import pluralize from "pluralize";
import OrderController from "./controllers/order-controller";
import { useRouter } from "vue-router";

export default {
    name: "CohoPrints",
    setup() {
        const database = inject(Database.InjectionKey);
        const auth = Auth.auth();
        const products = database?.collection<Product>("products").documents();
        const order = ref(new OrderController().order);
        const router = useRouter();

        router.afterEach(() => {
            order.value = new OrderController().order;
        });

        return {
            auth,
            order,
            products,
            pluralize,
        };
    },
};
</script>

<style lang="scss">
.alertbar {
    background-color: rgb(240, 212, 160);
    border: 1px solid rgb(190, 159, 101);
    box-shadow: 0px 1px 4px rgba(black, 0.1);
    border-radius: 5px;
    font-size: 0.9em;
    padding: 1em;
    color: rgb(129, 95, 31);
    text-align: center;
}
</style>
