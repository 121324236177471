
import OrderController from "@/controllers/order-controller";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "c-order-invoice",
    props: {
        orderId: {
            type: String,
            required: true,
        },
        coupon: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const orderController = new OrderController(props.orderId);
        const couponCode = ref("");
        const couponError = ref<string>();

        async function applyCoupon(): Promise<void> {
            await orderController
                .applyCoupon(couponCode.value)
                .then(() => {
                    couponError.value = undefined;
                })
                .catch((error) => {
                    couponError.value = error;
                });
        }

        function removeCoupon(): void {
            couponCode.value = "";
            orderController.removeCoupon();
        }

        return { order: orderController.order, couponCode, couponError, applyCoupon, removeCoupon };
    },
});
